import React from 'react';
import Layout from '../../components/organisms/Layout/Layout';
import PageBanner from '../../components/atoms/PageBanner/PageBanner';
import GiftCertificatesTab from '../../components/organisms/GiftCertificatesTab/GiftCertificatesTab';
import GetCertificateCodeForm from '../../components/molecules/GetCertificateCodeForm/GetCertificateCodeForm';
import emptyImage from './empty.png';

import styles from './giftCertificates.module.css';

const Title = ({ children }) => (
  <div className={styles.redeemTitle}>{children}</div>
);

const CheckGiftCertificateBalance = () => (
  <div className={styles.container}>
    <Title>
      You can check the balance of a gift certificate by typing the code in to
      the box below.
    </Title>
    <div className={styles.footerListRow}>
      <GetCertificateCodeForm />
    </div>
  </div>
);

const CheckGiftCertificateBalancePage = () => {
  return (
    <Layout>
      <PageBanner
        breadcrumb="Gift Certificates"
        title="Gift Certificates"
        color="powder"
        imageSize="medium"
        imageSrc={emptyImage}
      >
        <p>
          Sed ut perspiciatis unde omnis iste natus error sit voluptatem illo
          inventore accusantium doloremque laudantium, aperiam, eaque ipsa quae
          ab illo inventore veritatis et quasi architecto beatae vitae dicta
          sunt ipsam.
        </p>
      </PageBanner>

      <GiftCertificatesTab pageId={'check-gift-certificate-balance'}>
        <CheckGiftCertificateBalance />
      </GiftCertificatesTab>
    </Layout>
  );
};

export default CheckGiftCertificateBalancePage;
