import React from 'react';

import styles from './FormInputField.module.css';

const FormInputField = ({
  id,
  type = 'input',
  labelName,
  value,
  handleChange
}) => {
  return (
    <div className={`formField ${styles.formField}`}>
      <label htmlFor={id} className={styles.label}>
        {labelName}
      </label>
      {type === 'input' ? (
        <input
          id={id}
          type="text"
          value={value}
          className={styles.input}
          onChange={e => handleChange(id, e.target.value)}
        />
      ) : (
        <textarea
          id={id}
          name={id}
          value={value}
          className={styles.textarea}
          onChange={e => handleChange(id, e.target.value)}
        />
      )}
    </div>
  );
};

export default FormInputField;
