import React from 'react'

import Container from '../Container/Container'
import Divider from '../Divider/Divider'
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs'

import styles from './PageBanner.module.css'

import signup from './twitter.png'
import akab from './akab.png'

const PageBanner = ({ children, breadcrumb, title, color, imageSize, imageName, imageSrc, imageOnly, textOnly }) => {
  const internalImages = {
    signup,
    akab,
  }

  const classes = [styles.root];
  if (color in styles) {
    classes.push(styles[color])
  }
  if (imageSize in styles) {
    classes.push(styles[imageSize])
  }
  if (imageOnly && 'imageOnly' in styles) {
    classes.push(styles.imageOnly)
  }
  if (textOnly && 'textOnly' in styles) {
    classes.push(styles.textOnly)
  }
  const classOutput = classes.join(' ');

  return (
    <div className={classOutput}>
        <Container>
          {!imageOnly && (
            <div className={styles.containerContent}>
              <div className={styles.breadCrumb}>
                <Breadcrumbs crumbs={breadcrumb} />
              </div>
              <div className={styles.textContainer}>
                <h1 className={styles.header}>{title}</h1>
                {children && (
                    <div className={styles.subtitle}>{children}</div>
                )}
              </div>
            </div>  
          )}
          
          {!textOnly && (
            <div className={styles.imageContainer}>
            {imageName && (
              <img loading="lazy" src={internalImages[imageName]} alt={title} />
            )}
            {imageSrc && (
              <img loading="lazy" src={imageSrc} alt={title} />
            )}
          </div>
          )}
        </Container>
        <div className={styles.default}><Divider shape="teeth" /></div>
    </div>
  )
}

export default PageBanner
